<template>
  <!-- begin register -->
  <div class="page-content">
    <!-- <div class="right-content"> -->
    <!-- begin card -->
    <!-- <div class="card bg-grey-lighter m-b-20 col-md-12">
      <div class="card-body col-lg-4">
        <blockquote class="blockquote">
          <h2 class="page-header text-left text-black-lighter">企业信息</h2>
        </blockquote>
      </div>
    </div> -->
    <!-- end card -->
    <div class="register-content">
      <form v-on:submit="checkForm" method="POST">
        <div class="panel panel-inverse p-0" data-sortable-id="form-stuff-1">
          <div class="panel-header p-5">
            <h4 class="f-w-300 col-lg-10 col-md-8">
              <i class="fas fa-fw fa-outdent text-aqua-darker"></i>
              企业信息
            </h4>
          </div>

          <vue-custom-scrollbar style="height: 640px">
            <div class="p-5">
              <div class="invoice bg-light p-10">
                <h5 class="f-w-300">
                  <i class="fas fa-fw m-r-10 fa-building"></i>
                  公司信息
                </h5>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >公司名称：</label
                  >
                  <div class="col-lg-4 col-md-9 m-b-10">
                    <input
                      class="form-control"
                      data-parsley-range="[5,10]"
                      v-model="BasicInfomation.name"
                      required
                    />
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >社会信用代码：</label
                  >
                  <div class="col-lg-4 col-md-9 m-b-10">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.creditCode"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >所属行业：</label
                  >
                  <div class="col-lg-4 col-md-9 m-b-10">
                    <dictSelect
                      :show="false"
                      @select="industrySelect"
                      :value="BasicInfomation.industryId"
                      :pid="'3a021d7b-6c1b-98f5-f8cf-9404f68574a6'"
                    ></dictSelect>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >所属网格：</label
                  >
                  <div class="col-lg-4 col-md-9 m-b-10">
                    <v-select
                      :show="false"
                      v-model="BasicInfomation.gridId"
                      @input="gridIdSelect"
                      :reduce="
                        (gridheadEquipmentDict) => gridheadEquipmentDict.value
                      "
                      :options="gridheadEquipmentDict"
                    ></v-select>
                  </div>
                </div>
                <!-- radio-begin -->
                <div class="form-group row m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >安全性：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        name="default_radio"
                        id="inlineCssRadio1"
                        v-model="BasicInfomation.safetyLevel"
                        value="0"
                      />
                      <label for="inlineCssRadio1" class="text-red-lighter">
                        <span class="label label-danger">高危</span></label
                      >
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        name="default_radio"
                        v-model="BasicInfomation.safetyLevel"
                        id="inlineCssRadio2"
                        value="1"
                      />
                      <label for="inlineCssRadio2" class="text-yellow-lighter"
                        ><span class="label label-warning">危险</span></label
                      >
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        name="default_radio"
                        v-model="BasicInfomation.safetyLevel"
                        id="inlineCssRadio3"
                        value="2"
                      />
                      <label for="inlineCssRadio3" class="text-green-lighter"
                        ><span class="label label-green">安全</span>
                      </label>
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >所属区域：</label
                  >
                  <div class="col-lg-4 col-md-9 m-b-10">
                    <dictSelect
                      :show="false"
                      :value="BasicInfomation.regionId"
                      @select="regionIdIdSelect"
                      :pid="'3a019cfc-ef88-6b72-7bc4-f7c04aa3e94c'"
                    ></dictSelect>
                  </div>
                </div>
                <!-- radio-end -->
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >企业地址：</label
                  >
                  <div class="col-lg-10 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.certAddress"
                      />
                    </div>
                  </div>
                </div>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >详细地址：</label
                  >
                  <div class="col-lg-10 col-md-9">
                    <mapInput
                      v-on:charge="addressCharge"
                      ref="mapInput1"
                      :address="BasicInfomation.address"
                    ></mapInput>
                  </div>
                </div>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >法人代表：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        data-parsley-range="[5,10]"
                        v-model="BasicInfomation.legal"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >法人身份证：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        data-parsley-required="true"
                        v-model="BasicInfomation.legalIdCard"
                      />
                    </div>
                  </div>
                </div>
                <!-- begin  row -->
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >联系电话：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.legalTel"
                        placeholder="法人代表联系电话"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >主要产品：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.mainProduct"
                      />
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <!-- begin  row -->
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >生产许可编号：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        data-parsley-range="[5,10]"
                        v-model="BasicInfomation.productionNum"
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >从业人数：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.jobNum"
                        placeholder="0"
                        required
                      />
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <!-- begin  row -->
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >建筑面积：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        data-parsley-range="[5,10]"
                        v-model="BasicInfomation.floorage"
                        placeholder="平方米"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >产值(万)：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.outputValue"
                        placeholder="请输入产值(万)"
                        required
                      />
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <!-- radio-begin -->
                <div class="form-group row m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >安全生产诚信等级：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <select
                      class="form-control"
                      v-model="BasicInfomation.mainSincerityLevel"
                    >
                      <option>A</option>
                      <option>B</option>
                      <option>C</option>
                      <option>D</option>
                    </select>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >标准化等级：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <select
                      class="form-control"
                      v-model="BasicInfomation.mainStandardLevel"
                    >
                      <option>未评定</option>
                      <option>一级</option>
                      <option>二级</option>
                      <option>三级</option>
                    </select>
                  </div>
                </div>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >有危化品：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        id="inlineCssRadio4"
                        v-model="BasicInfomation.isDangerous"
                        value="true"
                      />
                      <label for="inlineCssRadio4">是</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isDangerous"
                        id="inlineCssRadio5"
                        value="false"
                        checked
                      />
                      <label for="inlineCssRadio5">否</label>
                    </div>
                  </div>

                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >厂房是否租聘：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        name="lease_default_radio"
                        id="leaseRadio1"
                        v-model="BasicInfomation.lease"
                        value="0"
                      />
                      <label for="leaseRadio1" class="text-red-lighter">
                        <span class="label label-danger">自有</span></label
                      >
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        name="lease_default_radio"
                        v-model="BasicInfomation.lease"
                        id="leaseRadio2"
                        value="1"
                      />
                      <label for="leaseRadio2" class="text-yellow-lighter"
                        ><span class="label label-warning">独租</span></label
                      >
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        name="lease_default_radio"
                        v-model="BasicInfomation.lease"
                        id="leaseRadio3"
                        value="2"
                      />
                      <label for="leaseRadio3" class="text-green-lighter"
                        ><span class="label label-green">群租</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    BasicInfomation.isDangerous == 'true' ||
                      BasicInfomation.isDangerous == true
                  "
                  class="row form-group m-b-10"
                >
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >是否独立危化品仓库：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        id="IsAddStorageCssRadio4"
                        v-model="BasicInfomation.isAddStorage"
                        value="true"
                        checked
                      />
                      <label for="IsAddStorageCssRadio4">是</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isAddStorage"
                        id="IsAddStorageCssRadio5"
                        value="false"
                      />
                      <label for="IsAddStorageCssRadio5">否</label>
                    </div>
                  </div>
                </div>
                <div v-else></div>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >备注：</label
                  >
                  <div class="col-lg-10 col-md-9">
                    <textarea
                      class="form-control col-lg-12 col-md-8"
                      rows="3"
                      data-parsley-range="[5,10]"
                      v-model="BasicInfomation.remarks"
                    ></textarea>
                  </div>
                </div>
              </div>
              <!-- panel-body end -->
              <!-- radio-end -->
              <hr class="p-t-2" />
              <div class="invoice bg-light p-10">
                <h5 class="width-150">
                  <i class="fas fa-user fa-1x"></i> 主要负责人
                </h5>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >姓名：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.mainUserName"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >电话号码：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        v-model="BasicInfomation.mainTel"
                        class="form-control"
                        placeholder="999-9999-9999"
                        required
                      />
                    </div>
                  </div>
                </div>
                <!-- 5.17号需求：去掉主要负责人 证件号等信息 -->
                <!-- <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >证件号：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.mainIdCard"
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >证件有效期：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <vxe-input v-model="BasicInfomation.mainValidity" type="date"></vxe-input>
                      <date-picker
                        id="dateTimeInput"
                        v-model="BasicInfomation.mainValidity"
                      ></date-picker>
                      <div class="input-group-append">
                        <label for="dateTimeInput" class="input-group-text">
                          <i class="fa fa-calendar"></i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div> -->
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >身份证号：</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.mainCardNum"
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >持证情况</label
                  >
                  <div class="col-lg-4 col-md-9">
                    <div class="input-group">
                      <select
                        class="form-control"
                        v-model="BasicInfomation.mainSafeLevel"
                      >
                        <option>无</option>
                        <option>安全生产企业主要负责人证书</option>
                        <option>初级注册安全生产工程师</option>
                        <option>中级注册安全生产工程师</option>
                        <option>高级注册安全生产工程师</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <hr class="p-t-2" />
              <div class="invoice bg-light p-10">
                <h5 class="f-w-300">
                  <i class="fas fa-fw m-r-10 fa-building"></i>
                  公司信息
                </h5>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >营业执照：</label
                  >
                  <div class="page-content col-lg-4 col-md-9">
                    <div>
                      <upload
                        :maxSize="10"
                        :value="BasicInfomation.businessLicense"
                        @finishUpload="finishAll"
                      >
                      </upload>
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-3 col-form-label text-right"
                    >生产许可证：</label
                  >
                  <div class="page-content col-lg-4 col-md-9">
                    <div>
                      <upload
                        :maxSize="10"
                        :value="BasicInfomation.productionLicense"
                        @finishUpload="finishAll1"
                      >
                      </upload>
                    </div>
                  </div>
                </div>
              </div> -->

              <hr class="p-t-2" />
              <div class="invoice bg-light p-10">
                <h5 class="width-150">
                  <i class="fas fa-fw m-r-10 fa-th-list"></i>制度建设
                </h5>
                <!-- radio-begin -->
                <div class="form-group row m-b-10">
                  <label class="col-lg-3 col-md-4 col-form-label text-right"
                    >有安全生产责任书</label
                  >
                  <div class="col-lg-3 col-md-8">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isSafeBook"
                        id="CertRadio3"
                        value="true"
                        checked
                      />
                      <label for="CertRadio3">是</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="CertRadio4"
                        value="false"
                        v-model="BasicInfomation.isSafeBook"
                      />
                      <label for="CertRadio4">否</label>
                    </div>
                  </div>
                  <label class="col-lg-3 col-md-4 col-form-label text-right"
                    >有教育培训制度</label
                  >
                  <div class="col-lg-3 col-md-8">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        id="CertRadio5"
                        v-model="BasicInfomation.isEduTrain"
                        value="true"
                        checked
                      />
                      <label for="CertRadio5">是</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="CertRadio6"
                        value="false"
                        v-model="BasicInfomation.isEduTrain"
                      />
                      <label for="CertRadio6">否</label>
                    </div>
                  </div>
                </div>
                <!-- radio-end -->
                <!-- radio-begin -->
                <div class="form-group row m-b-10">
                  <label class="col-lg-3 col-md-4 col-form-label text-right"
                    >有安全生产规章制度及操作规范</label
                  >
                  <div class="col-lg-3 col-md-8">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isFGMP"
                        id="CertRadio7"
                        value="true"
                        checked
                      />
                      <label for="CertRadio7">是</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        id="CertRadio8"
                        value="false"
                        v-model="BasicInfomation.isFGMP"
                      />
                      <label for="CertRadio8">否</label>
                    </div>
                  </div>
                  <label class="col-lg-3 col-md-4 col-form-label text-right"
                    >有设备检查与检修制度</label
                  >
                  <div class="col-lg-3 col-md-8">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isCheckUp"
                        id="CertRadio9"
                        value="true"
                        checked
                      />
                      <label for="CertRadio9">是</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isCheckUp"
                        id="CertRadio10"
                        value="false"
                      />
                      <label for="CertRadio10">否</label>
                    </div>
                  </div>
                </div>
                <!-- radio-end -->
                <!-- radio-begin -->
                <div class="form-group row m-b-10">
                  <label class="col-lg-3 col-md-4 col-form-label text-right"
                    >有安全隐患排查制度</label
                  >
                  <div class="col-lg-3 col-md-8">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isDangerCheck"
                        id="CertRadio11"
                        value="true"
                        checked
                      />
                      <label for="CertRadio11">是</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isDangerCheck"
                        id="CertRadio12"
                        value="false"
                      />
                      <label for="CertRadio12">否</label>
                    </div>
                  </div>
                  <label class="col-lg-3 col-md-4 col-form-label text-right"
                    >有工伤保险</label
                  >
                  <div class="col-lg-3 col-md-8">
                    <div class="radio radio-css radio-inline m-r-15">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isInjuryInsure"
                        id="CertRadio13"
                        value="true"
                        checked
                      />
                      <label for="CertRadio13">是</label>
                    </div>
                    <div class="radio radio-css radio-inline">
                      <input
                        type="radio"
                        v-model="BasicInfomation.isInjuryInsure"
                        id="CertRadio14"
                        value="false"
                      />
                      <label for="CertRadio14">否</label>
                    </div>
                  </div>
                </div>
                <!-- radio-end -->
              </div>

              <hr class="p-t-2" />
              <div class="invoice bg-light p-10">
                <h5 class="width-150">
                  <i class="fas fa-fire-extinguisher fa-1x"></i> 消防器材登记
                </h5>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-4 col-form-label text-right"
                    >灭火器数量：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        data-parsley-range="[5,10]"
                        v-model="BasicInfomation.extinguisher"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-4 col-form-label text-right"
                    >消防栓数量：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.hydrant"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-md-4 col-lg-2 col-form-label text-right"
                    >消防泵数量：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.firePumps"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-4 col-form-label text-right">
                    沙驰数量：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        data-parsley-range="[5,10]"
                        v-model="BasicInfomation.basin"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-4 col-form-label text-right">
                    其他数量：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.other"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <hr class="p-t-2" />
              <div class="invoice bg-light p-10 p-b-10">
                <h5 class="width-150">
                  <i class="ion-md-construct 1x"></i> 特种设备登记
                </h5>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-4 col-form-label text-right"
                    >锅炉数量(个)：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        data-parsley-range="[5,10]"
                        v-model="BasicInfomation.boiler"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-4 col-form-label text-right"
                    >起重机数量(辆)：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.crane"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-md-4 col-lg-2 col-form-label text-right"
                    >电梯数量(台)：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.elevator"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="row form-group m-b-10">
                  <label class="col-lg-2 col-md-4 col-form-label text-right">
                    专用机动车数量(辆)：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        data-parsley-range="[5,10]"
                        v-model="BasicInfomation.car"
                        required
                      />
                    </div>
                  </div>
                  <label class="col-lg-2 col-md-4 col-form-label text-right">
                    特种其他数量：</label
                  >
                  <div class="col-lg-2 col-md-8">
                    <div class="input-group">
                      <input
                        class="form-control"
                        v-model="BasicInfomation.specialOther"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-custom-scrollbar>
        </div>
        <p class="text-right p-b-5">
          <button class="btn btn-sm btn-success m-r-10" type="submit">
            <i class="fa fa-save"></i> 保存
          </button>
          <button
            type="button"
            @click="$emit('close')"
            class="btn btn-sm btn-grey m-r-10"
          >
            ❌关闭
          </button>
        </p>
      </form>
    </div>

    <b-modal id="modalAdds" no-fade cancel-variant="default" title="请选择">
      <p>Modal body content here...</p>
    </b-modal>
    <!-- end register-content -->
  </div>
</template>
<script>
// import PageOptions from '../config/PageOptions.vue'
import { userApi } from "@/api/system/userApi";
import { businessApi } from "@/api/system/businessApi";
import dictSelect from "@/components/dictSelect/index.vue";
import mapInput from "@/components/map-input/index.vue";

const MODAL_WIDTH = 1024;
export default {
  props: ["Companyid"],
  components: {
    dictSelect,
    mapInput,
  },
  data() {
    return {
      tablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      TtablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      RtablePage: {
        currentPage: 1,
        pageSize: 10,
        totalResult: 0,
      },
      loading: false,
      validRules: {
        Name: [{ required: true, message: "名称必须填写" }],
      },
      TtableData: [],
      RtableData: [],
      tableData: [],
      //存放提交的数据证照ID
      businesspicture: "",
      productionpicture: "",
      //特种设备数据字典集
      SpecialEquipmentDict: [],
      gridheadEquipmentDict: [],
      BasicInfomation: {
        name: "",
        address: "",
        regionId: "",
        gridId: "",
        industryId: "",
        safetyLevel: "2",
        businessLicense: [],
        creditCode: "",
        legal: "",
        legalIdCard: "",
        legalTel: "",
        mainProduct: "",
        productionLicense: [],
        productionNum: "",
        jobNum: "",
        floorage: "",
        outputValue: "",
        isDangerous: false,
        mainUserName: "",
        mainTel: "",
        mainIdCard: "",
        // mainIsCard: "true",
        mainValidity: "",
        mainSincerityLevel: "",
        mainStandardLevel: "",
        mainSafeLevel: "",
        isSafeBook: "true",
        isEduTrain: "true",
        isFGMP: "true",
        isCheckUp: "true",
        isDangerCheck: "true",
        isInjuryInsure: "true",
        extinguisher: "",
        hydrant: "",
        firePumps: "",
        other: "",
        basin: "",
        boiler: "",
        crane: "",
        elevator: "",
        car: "",
        specialOther: "",
        longitude: "0",
        latitude: "0",
        certAddress: "",
        mainCardNum: "",
        lease: "0",
        remarks: "",
        isAddStorage: true,
        id: "",
      },
      id: [],
      modalWidth: MODAL_WIDTH,
    };
  },
  watch: {
    // 监听路由，路由发生变化请求数据
    $route(to, from) {
      if (to.path == "/CompanyInfo") {
        this.$router.replace(from.fullpath);
      }
    },
  },
  mounted() {
    this.id = this.Companyid;
    if (this.id) {
      this.GetData();
    }
    this.GetUsersList();
  },
  created() {
    this.modalWidth =
      window.innerWidth < MODAL_WIDTH ? window.innerWidth * 0.8 : MODAL_WIDTH;

    var dict = sessionStorage.getItem("SpecialEquipment");
    console.log(dict);
  },
  methods: {
    // 保存成功提示框
    ToastSuccess(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "Success") {
        title = "保存成功";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "success",
        title: title,
      });
    },
    // 失败提示框
    ToastWarning(res) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      var title = [];
      if (res.message == "False") {
        title = "保存出错";
      } else {
        title = res.message;
      }
      Toast.fire({
        icon: "warning",
        title: title,
      });
    },
    //请求Api获取企业信息
    GetData() {
      let that = this;
      businessApi
        .GetData({
          //入参为地址栏传入的ID值
          id: that.Companyid,
        })
        .then((res) => that.GetSuccess(that, res))
        .finally(() => {});
    },

    GetSuccess(that, res) {
      if (res.success) {
        // console.log(res.data);
        that.BasicInfomation = res.data;
        // 把证件ID存到数组中让组件读取
        (that.BasicInfomation.businessLicense = [res.data.businessLicense]),
          (that.BasicInfomation.productionLicense = [
            res.data.productionLicense,
          ]),
          // 把证件ID存放字符串类型
          (that.businesspicture = res.data.businessLicense[0]),
          (that.productionpicture = res.data.productionLicense[0]),
          //异步获取地址，并向组件传值
          this.$refs.mapInput1.initAdds({
            address: this.BasicInfomation.address,
            coordinate: [
              this.BasicInfomation.longitude,
              this.BasicInfomation.latitude,
            ],
          });
      } else {
        console.log(res);
      }
    },
    checkForm: function(e) {
      e.preventDefault();
      console.log(this.address);
      //提交的数据
      const params = {
        id: this.id,
        name: this.BasicInfomation.name, //公司名称
        address: this.BasicInfomation.address, //公司地址
        regionId: this.BasicInfomation.regionId, //所属区域Id
        safetyLevel: this.BasicInfomation.safetyLevel, //安全性: 0:高危 1:危险 2:安全
        gridId: this.BasicInfomation.gridId, //所属网格Id
        industryId: this.BasicInfomation.industryId, //所属行业Id
        businessLicense: this.businesspicture, //营业执照
        creditCode: this.BasicInfomation.creditCode, //统一社会信用代码
        legal: this.BasicInfomation.legal, //法人代表
        legalIdCard: this.BasicInfomation.legalIdCard, //法人身份证号
        legalTel: this.BasicInfomation.legalTel, //法人联系电话
        mainProduct: this.BasicInfomation.mainProduct, //主要产品
        productionLicense: this.productionpicture, //生产许可证
        productionNum: this.BasicInfomation.productionNum, //生产许可编号
        jobNum: this.BasicInfomation.jobNum, //从业人数
        floorage: this.BasicInfomation.floorage, //建筑面积
        outputValue: this.BasicInfomation.outputValue, //产值
        isDangerous: this.BasicInfomation.isDangerous, //是否有危化品
        mainUserName: this.BasicInfomation.mainUserName, //主要负责人姓名
        mainTel: this.BasicInfomation.mainTel, //手机号码
        mainIdCard: this.BasicInfomation.mainIdCard, //证件号
        // mainIsCard: this.BasicInfomation.mainIsCard, //是否持证
        mainValidity: this.BasicInfomation.mainValidity, //有效期
        mainSincerityLevel: this.BasicInfomation.mainSincerityLevel, //安全生产诚信等级
        mainStandardLevel: this.BasicInfomation.mainStandardLevel, //标准等级
        mainSafeLevel: this.BasicInfomation.mainSafeLevel, //安全等级
        isSafeBook: this.BasicInfomation.isSafeBook, //有安全生产责任书
        isEduTrain: this.BasicInfomation.isEduTrain, //有教育与培训制度
        isFGMP: this.BasicInfomation.isFGMP, //有安全生产规章制度及操作规范
        isCheckUp: this.BasicInfomation.isCheckUp, //有设备检查与检修制度
        isDangerCheck: this.BasicInfomation.isDangerCheck, //有安全隐患排查制度
        isInjuryInsure: this.BasicInfomation.isInjuryInsure, //有工伤保险
        extinguisher: this.BasicInfomation.extinguisher, //灭火器数量
        hydrant: this.BasicInfomation.hydrant, //消防栓数量
        firePumps: this.BasicInfomation.firePumps, //消防泵数量
        other: this.BasicInfomation.other, //沙池数量
        basin: this.BasicInfomation.basin, //其他数量
        boiler: this.BasicInfomation.boiler, //锅炉数量
        crane: this.BasicInfomation.crane, //起重机数量
        elevator: this.BasicInfomation.elevator, //电梯数量
        car: this.BasicInfomation.car, //专用机动车数量
        specialOther: this.BasicInfomation.specialOther, //特种其他数量
        longitude: this.BasicInfomation.longitude, //经度
        latitude: this.BasicInfomation.latitude, //维度
        certAddress: this.BasicInfomation.certAddress, //企业地址
        mainCardNum: this.BasicInfomation.mainCardNum, //主要负责人身份证
        lease: this.BasicInfomation.lease, //厂房是否租聘
        remarks: this.BasicInfomation.remarks, //企业备注
        isAddStorage: this.BasicInfomation.isAddStorage, //企业备注
      };
      if (
        this.BasicInfomation.regionId == null ||
        this.BasicInfomation.regionId == ""
      ) {
        this.$swal({
          title: "",
          text: "请选择所属区域", //提示语
          icon: "warning",
          type: "question", //类型
          showCancelButton: false, //  显示取消按钮
          buttonsStyling: false,
          // confirmButtonText: "确定",
          // cancelButtonText: "取消",
          confirmButtonClass: "btn m-r-5 btn-" + "error" + "",
          cancelButtonClass: "btn btn-default",
        });
        return;
      } else if (
        this.BasicInfomation.industryId == null ||
        this.BasicInfomation.industryId == ""
      ) {
        this.$swal({
          title: "",
          text: "请选择所属行业", //提示语
          icon: "warning",
          type: "question", //类型
          showCancelButton: false, //  显示取消按钮
          buttonsStyling: false,
          confirmButtonClass: "btn m-r-5 btn-" + "error" + "",
          cancelButtonClass: "btn btn-default",
        });
        return;
      } else if (
        this.BasicInfomation.gridId == null ||
        this.BasicInfomation.gridId == ""
      ) {
        this.$swal({
          title: "",
          text: "请选择所属网格", //提示语
          type: "提示", //类型
          icon: "warning",
          showCancelButton: false, //  显示取消按钮
          buttonsStyling: false,
          confirmButtonClass: "btn m-r-5 btn-" + "确定" + "",
          cancelButtonClass: "btn btn-default",
        });
        return;
      } else {
        e.preventDefault();
        let that = this;
        // 通过地址栏ID判断新增还是修改
        if (that.id) {
          // that.params.id=that.id
          businessApi
            .UpdataCompany(params)
            .then((res) => that.UpdataSuccess(that, res))
            .finally(() => {});
        } else {
          //新建公司信息
          userApi
            .CreateCompany(params)
            .then((res) => that.createSuccess(that, res))
            .finally(() => {});
        }
      }
    },
    UpdataSuccess(that, res) {
      if (res.success) {
        this.$emit("close");
        that.ToastSuccess(res);
      } else {
        that.ToastWarning(res);
      }
    },
    createSuccess(that, res) {
      if (res.success) {
        // 创建成功后提示
        that.ToastSuccess(res);
        this.$emit("close");
      } else {
        // 创建失败提示
        that.ToastWarning(res);
      }
    },
    checktable: function() {},
    //文件上传成功后返回的文件组
    finishAll(e) {
      this.BasicInfomation.businessLicense = e;
      this.businesspicture = e[0];
    },
    finishAll1(e) {
      console.log(e);
      this.BasicInfomation.productionLicense = e;
      this.productionpicture = e[0];
    },
    //接收MapInput组件传回的值
    addressCharge() {
      if (
        arguments.length > 0 &&
        arguments[1][0] !== undefined &&
        arguments[1][1] !== undefined
      ) {
        var n = arguments[1].split(",");
        this.BasicInfomation.longitude = n[0];
        this.BasicInfomation.latitude = n[1];
        console.log("addressCharge", arguments);
      }
      this.BasicInfomation.address = arguments[0];
    },
    //行业选择框返回值
    industrySelect(id) {
      this.BasicInfomation.industryId = id;
      console.log("industrySelect", id);
    },
    //所属区域选择框返回值
    regionIdIdSelect(id) {
      this.BasicInfomation.regionId = id;
      console.log("regionIdIdSelect", id);
    },
    //所属网格选择框返回值
    gridIdSelect(id) {
      console.log("gridIdSelect", id);
      this.BasicInfomation.gridId = id;
    },
    //读取网格长数据字典
    GetUsersList() {
      let that = this;
      let options = [];
      that.gridheadEquipmentDict = [];
      businessApi
        .GetUsersList({
          SkipCount: 0,
          MaxResultCount: 100,
          Sorting: "",
          Filter: "",
          RoleId: "2",
        })
        .then((res) => {
          res.data.items.forEach((element) => {
            options.push({
              value: element.id,
              label: element.name,
            });
          });
          that.gridheadEquipmentDict = options;
        });
    },
  },
};
</script>
